<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：领料单新建页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="">
    <el-dialog title="新增单据" :visible.sync="show_billsCreateBox" :before-close="colse_createBox" :fullscreen="true">
      <div class="billingcreate">
        <!-- 单据头部 -->
        <div class="billingcreate_head">
          <!-- 头部左边单据日期 -->
          <div class="head_left">
            <!-- 单据日期 -->
            <div class="title">
              <span>日期:</span>
            </div>
            <div class="date">
              <el-date-picker type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>

          <!-- 中间公司名 -->
          <div class="head_content">
            <span class="billing_Company">
              易外销科技有限公司领料单
            </span>
          </div>

          <!-- 头部右边单据操作 -->
          <div class="head_right"></div>
        </div>

        <!-- 单据表单 -->
        <div class="billingcreate_form">
          <ul class="form_ul">
            <li>
              <div class="form_title">
                生产单号:
              </div>
              <div class="form_date">
                <input type="text" placeholder="可扫码输入">
                <span>
                  <i class="el-icon-search"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">单据编号:</div>
              <div class="form_date">
                <input type="text" placeholder="选择...">
              </div>
            </li>
            <li>
              <div class="form_title">
                产品名称:
              </div>
              <div class="form_date">
                <input type="text" placeholder="选择...">
              </div>
            </li>
            <li>
              <div class="form_title">计划生产数:</div>
              <div class="form_date">
                <input type="text" placeholder="选择...">
              </div>
            </li>
            <li>
              <div class="form_title">计划完工:</div>
              <div class="form_date" @click="show_djlx=!show_djlx">
                <input type="text" placeholder="选择...">
              </div>
            </li>
          </ul>
        </div>

        <!-- 单据表格 -->
        <div class="billing_table">
          <el-table :data="tableData" height="100%" border show-summary style="width: 100%">
            <!-- 索引 -->
            <el-table-column type="index" fixed width="50"></el-table-column>
            <!-- 全选 -->
            <el-table-column type="selection" fixed width="45"></el-table-column>
            <!-- 操作 -->
            <el-table-column label="操作" fixed width="80">
              <template  slot-scope="scope">
                <div class="operate">
                  <i class="el-icon-circle-plus-outline operate_icon" @click="add"></i>
                  <i class="el-icon-remove-outline operate_icon" @click="remove(scope.$index)"></i>
                </div>
              </template>
            </el-table-column>
            <!-- 数据内容 -->
            <el-table-column prop="wlbh" label="物料编号" sortable width="180">
              <template  slot-scope="scope">
                <el-input size="mini" v-model="scope.row.wlbh" @click.native="get()" placeholder="请输入内容"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="wlmc" label="物料名称" width="120"></el-table-column>
            <el-table-column prop="ggxh" label="规格型号" width="120"></el-table-column>
            <el-table-column prop="ys" label="颜色" width="180"></el-table-column>
            <el-table-column prop="kykc" label="可用库存" width="80"></el-table-column>
            <el-table-column prop="sxsl" label="所需数量" width="80"></el-table-column>
            <el-table-column prop="ylls" label="已领料数" width="120"></el-table-column>
            <el-table-column prop="bcll" label="本次领料" width="150"></el-table-column>
            <el-table-column prop="jldw" label="计量单位" width="150"></el-table-column>
            <el-table-column prop="bz" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div>

        <!-- 单据底部 -->
        <div class="billingcreate_form">
            <ul class="form_ul">
              <li>
                <div class="form_title">经办人:</div>
                <div class="form_date">
                  <input type="text" placeholder="选择...">
                  <span><i class="el-icon-arrow-down"></i></span>
                </div>
              </li>
              <li>
                <div class="form_title">所属人:</div>
                <div class="form_date">
                  <input type="text" placeholder="选择...">
                  <span><i class="el-icon-arrow-down"></i></span>
                </div>
              </li>
              <li>
                <div class="form_title">审批人:</div>
                <div class="form_date">
                  <input type="text" placeholder="选择...">
                  <span><i class="el-icon-arrow-down"></i></span>
                </div>
              </li>
            </ul>
          </div>
      </div>

      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="SHOW_BILLSCREATEBOX(false)">生成单据</el-button>
        <el-button type="success" @click="SHOW_BILLSCREATEBOX(false)">提交审批</el-button>
        <el-button type="success" @click="SHOW_BILLSCREATEBOX(false)">保存并审核</el-button>
        <el-button type="primary" @click="SHOW_BILLSCREATEBOX(false)">清空</el-button>
        <el-button @click="SHOW_BILLSCREATEBOX(false)">取消</el-button>
      </span>
    </el-dialog>

    <!-- 单据选择组件 -->
    <billingList></billingList>
  </div>
</template>

<script>
  // 导入组件
  import billingList from '@/components/commComponent/list/list_billing';//单据列表组件

  // 导入vuex
  import {mapMutations,mapState,mapActions} from 'vuex'

  export default{
    name:'requisitionmaterialCreate',
    data(){
      return{
        //控制开关
        show_djlx:false,

        // 表单数据
        form_data:{
          blrq:'',
        },

        //表格数据
        tableData:[
          {
            wlbh:'p202112525',
            wlmc:'物料名称',
            ggxh:'12*12',
            ys:'红色',
            lykc:'0',
            sxsl:'0',
            ylls:'0',
            bcll:'0',
            jldw:'个',
            bz:''
          }
        ],
      }
    },
    computed:{
      ...mapState({
        show_billsCreateBox: state => state.billing.show_billsCreateBox,//控制显示新建弹框组件
      }),
    },
    created() {
    },
    mounted() {
    },
    methods:{
      ...mapMutations([
        'SHOW_BILLSCREATEBOX',//控制新建弹框是否显示
        'SHOW_BILLINGBOX',//控制单据选择弹框是否显示
      ]),
      colse_createBox(){
        this.SHOW_BILLSCREATEBOX(false);
      },

      add(){
        this.tableData.push(
          {
            wlbh:'p202112525',
            wlmc:'物料名称',
            ggxh:'12*12',
            ys:'红色',
            lykc:'0',
            sxsl:'0',
            ylls:'0',
            bcll:'0',
            jldw:'个',
            bz:''
          }
        )
      },

      remove(index){
        if(index>0){
          this.tableData.splice(index,1)
        }
      },

      get(col){
        this.SHOW_BILLINGBOX(true);
      },
    },
    components:{
      billingList
    }
  }
</script>

<style scoped="scoped">
  .billing_table{
    height: 56vh;
  }
</style>
