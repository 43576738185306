<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：领料单视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview returnview">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li>
            全部
          </li>
          <li>
            已审
          </li>
          <li>
            未审
          </li>
          <li>
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                状态<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>未出库</el-dropdown-item>
                <el-dropdown-item>部分出库</el-dropdown-item>
                <el-dropdown-item>待出库</el-dropdown-item>
                <el-dropdown-item>已发库</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li >
            <el-popover placement="bottom" width="400" v-model="show_searchBox" trigger="manual">
              <el-form ref="form" :model="form" size="mini" label-width="100px">
                <el-form-item>
                  <div class="billingview_searchTitle">
                    <span>根据以下条件搜索:</span>
                  </div>
                </el-form-item>
                <el-form-item label="单号:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="客户名:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="起止日期:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="货品编号:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="订单号:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="仓库:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="所属部门:">
                  <el-input v-model="input" size="small" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button type="primary">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="SHOW_BILLSCREATEBOX(true)">
            <i class="el-icon-plus"></i>新增
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li>
            <i class="el-icon-plus"></i>审批
          </li>
          <li>
            <i class="el-icon-plus"></i>出库
          </li>
          <li>
            <i class="el-icon-plus"></i>打印
          </li>
          <li>
            <i class="el-icon-plus"></i>导出
          </li>
        </ul>
      </div>
    </div>

    <!-- 报价表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table :data="tableData" height="100%" border show-summary style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">出库</el-dropdown-item>
                <el-dropdown-item icon="el-icon-view">查看</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="dh" label="单号" sortable width="180"></el-table-column>
        <el-table-column prop="rq" label="日期" width="120"></el-table-column>
        <el-table-column prop="ydjh" label="源单据号" width="180"></el-table-column>
        <el-table-column prop="djlx" label="单据类型" width="80"></el-table-column>
        <el-table-column prop="khmc" label="客户名称" width="80"></el-table-column>
        <el-table-column prop="ck" label="仓库" width="120"></el-table-column>
        <el-table-column prop="zdr" label="制单人" width="80"></el-table-column>
        <el-table-column prop="zt" label="状态" width="80">
          <!-- <template slot-scope="scope">
            <el-button type="success" size="mini">{{scope.row.zt}}</el-button>
          </template> -->
        </el-table-column>
        <el-table-column prop="shr" label="审核人" width="80"></el-table-column>
        <el-table-column prop="shsj" label="审核时间" width="100"></el-table-column>
        <el-table-column prop="dy" label="打印" width="80"></el-table-column>
        <el-table-column prop="gsxm" label="归属项目" width="80"></el-table-column>
        <el-table-column prop="ssbm" label="所属部门" width="120"></el-table-column>
        <el-table-column prop="bz" label="备 注" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <!-- 新增领料单单据组件 -->
    <requisitionmaterialCreate></requisitionmaterialCreate>
  </div>
</template>

<script>
  // 导入vuex
  import {mapMutations,mapState,mapActions} from 'vuex'

  // 导入组件
  import requisitionmaterialCreate from './requisitionmaterial_create.vue';//新建报价组件

  export default{
    name:'requisitionmaterialView',
    data(){
      return{
        //控制开关
        show_offerAdd:false,//是否显示新建开单
        show_productList:false,//是否显示产品选择弹框
        show_searchBox:false,//是否显示搜索

        show_create:false,

        //下标
        index_tr:-1,//正在操作的下标


        //表格数据
        tableData:[],//传入表格组件的数据


        // 测试用
        form:{},
        input:'',
        currentPage4:0,
      }
    },
    created() {
      // 获取表格组件数据
      this.get_tableData();
    },
    mounted() {

    },
    methods:{
      ...mapMutations([
        'SHOW_BILLSCREATEBOX',//控制新建弹框是否显示
      ]),
      // 测试用
      handleSizeChange(){},
      handleCurrentChange(){},

      // 获取表格数据
      get_tableData(){
        this.tableData=[
          {
            dh:'pro20201215',
            rq:'2020-02-01',
            ydjh:'pro20201215',
            djlx:'补料单',
            khmc:'客户名称',
            ck:'龙岗仓',
            zdr:'张三',
            zt:'已审批',
            shr:'李四',
            shsj:'2020-02-01',
            dy:'0',
            gsxm:'项目名',
            ssbm:'部门名',
            bz:'备注信息',
          },
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        ]
      },

      // 新建领料单
      show_createBox(){//显示领料单
        this.show_replenish=true;
      },
      show_replenishBox(){},
      show_replenish(){},
      hide_createBox(){//隐藏领料单
        this.show_replenish=false;
      },
    },
    components:{
      requisitionmaterialCreate
    }
  }
</script>
